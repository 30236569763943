import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

class ThankYou2Page extends React.Component {
  render() {
    const siteTitle = "Thank You"
    const siteDescription = this.props.data.site.siteMetadata.description
    return (
      <Layout location={this.props.location} title={`Thank You!`}>
        <Seo title={siteTitle} description={siteDescription} />
        <section className="component mt-5">
          <div className="container">
            <div className="row">
              <div className="mx-auto col-md-8 col-sm-10 text-center">
                <h3>
                  Thank you! We've received your message and will be in touch
                  soon.
                </h3>
                <div className="d-flex mt-2 justify-content-center">
                  <Link
                    aria-label="link"
                    to="/knowledge-base/"
                    className="btn btn-primary mt-3"
                  >
                    RETURN TO KNOWLEDGE BASE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default ThankYou2Page

export const ThankYou2AgencyQuery = graphql`
  query ThankYou2AgencyQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
